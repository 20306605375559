<template> 
 <div class="w-full h-screen">
          <iframe
            :src="url_map"
            width="100%"
            style="border: 1px solid rgba(0, 0, 0, 0.1);" 
            height="100%" 
            allowfullscreen
            frameborder="0" >
           </iframe>
    </div>
</template>

<script> 
export default {
  components: { 
  },
  data() {
    return {
      url_map: undefined,
    };
  },
  created(){     
    console.log("created");
    console.log(process.env);     
    this.url_map = process.env.VUE_APP_URL_MAP;     
  }
};
</script>

<style scoped>
</style>